<template>
  <div class="app-container">
    <el-card>
      <div slot="header" class="card-header">
        <h3 style="margin: 0;">BSC Approval Dashboard</h3>
        <div v-if="!viewSection">
          <el-select v-model="selectedYear" size="mini" @change="getBSCApproval()" clearable placeholder="Select Year">
            <el-option  v-for="year in yearList" :key="year.id" :value="year.year" :label="year.year"></el-option>
          </el-select>
          <el-select v-model="selectedEval" size="mini" style="margin-left:10px;" @change="getBSCApproval()" clearable placeholder="Select Evaluation">
            <el-option v-for="evaluation in completedEvalList" :key="evaluation.id" :value="evaluation.id" :label="evaluation.name"></el-option>
          </el-select>
        </div>
      </div>
      <div v-if="viewSection">
        <el-row><el-button @click="viewSection = false" size="mini"><i class="el-icon-back"></i></el-button></el-row>
        <h1>{{selectedSection.bds_id_name  + ' - ' + selectedSection.bj_id_name}}</h1>
        <el-collapse v-for="(parentItem, parentIndex) in bscTableData" :key="parentIndex">
          <el-collapse-item :title="parentItem.category_bg4_id_name + ' - ' + parentItem.weight + '%'" :name="parentItem.category_bg4_id_name">
            <div v-for="(childItem, childIndex) in parentItem.perspective" :key="childIndex">
              <h1 style="font-size:13px !important;">{{childItem.perspective_bg4_id_name}}</h1>
              <div v-for="(grandChildItem, grandChildIndex) in childItem.strategic_objectives" :key="grandChildIndex">
                <h2>{{grandChildItem.objective_bg4_id_name}}</h2>
                <el-table
                  :data="grandChildItem.attributes"
                  style="width: 100%">
                  <el-table-column
                    prop="weight"
                    label="% Weight"
                    >
                  </el-table-column>
                  <el-table-column
                    prop="measurement_bg4_id_name"
                    label="Strategic Measurements (KPI)"
                    >
                  </el-table-column>
                  <el-table-column
                    prop="threshold"
                    label="Threshold"
                    >
                  </el-table-column>
                  <el-table-column
                    prop="target"
                    label="Target"
                    >
                  </el-table-column>
                  <el-table-column
                    prop="superior"
                    label="Superior"
                    >
                  </el-table-column>
                  <el-table-column
                    prop="actual"
                    label="Actual"
                    >
                  </el-table-column>
                </el-table>
                <h3>Sub-Total: {{computeTotal(grandChildItem)}}</h3>
              </div>
              <hr>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div v-else>
        <el-row type="flex" justify="end">
          <el-col></el-col>
          <el-col :sm="8" :md="5" :lg="5" :xl="5">
            <el-input
              v-model="deptSearchText"
              size="mini"
              placeholder="Search for Evaluation"
            />
          </el-col>
        </el-row>
        <el-table
          :data="approvalTable.filter(data => !deptSearchText || data.evaluation_name.toLowerCase().includes(deptSearchText.toLowerCase()))"
          >
          <el-table-column
            prop="evaluation_name"
            label="Name"
            >
            <template slot-scope="scope">
              <span @click="getSection(scope.row)" style="cursor:pointer;">{{scope.row.evaluation_name}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="bms_id_name"
            label="Balance Score Card"
            >
          </el-table-column>
          <el-table-column
            prop="head_a_id_name"
            label="Status"
            >
          </el-table-column>
          <el-table-column
            fixed="right"
            label="Action"
            align="right"
            >
            <template slot-scope="scope" style="text-align: center;">
              <el-switch
                v-model="scope.row.head_a_id"
                active-color="#ff4949"
                inactive-color=" #D3D3D3"
                active-value="1"
                inactive-value="0"
                @change="handleSwitch($event, scope.row)"
                >
              </el-switch>
            </template>
          </el-table-column>
        </el-table>
        <el-row type="flex" style="padding-top: 10px;" justify="space-between">
          <el-col><h3>SECTIONS <span v-if="selectedTableEval !== ''">-</span> {{selectedTableEval.evaluation_name}}</h3></el-col>
          <el-col :sm="8" :md="5" :lg="5" :xl="5">
            <el-input
              v-model="evalSearchText"
              size="mini"
              @click="hello(scope.row)"
              placeholder="Search for Section"
            />
          </el-col>
        </el-row>
        <el-table
          :data="sectionTable.filter(data => !evalSearchText || data.bj_id_name.toLowerCase().includes(evalSearchText.toLowerCase()))"
          style="width: 100%">
          <el-table-column
            prop="bds_id_name"
            label="Section"
            >
          </el-table-column>
          <el-table-column
            prop="bj_id_name"
            label="Rank"
            >
          </el-table-column>
          <el-table-column
            fixed="right"
            label="Action"
            align="right"
            width="200"
            >
            <template slot-scope="scope">
              <el-button style="background:#EB2C3E; color:white;" @click="viewBSC(scope.row)" size="mini">View BSC</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  created () {
    this.initGetBSCApproval()
    this.getCompletedEval()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    // this.getEvalDepts()
  },
  computed: {
    labelPos () {
      if (this.windowWidth < 992) {
        return 'top'
      } else {
        return 'left'
      }
    }
  },
  data () {
    return {
      selectedSection: '',
      bscTableData: [],
      viewSection: false,
      windowWidth: window.innerWidth,
      sectionTable: [],
      approvalTable: [],
      selectedEval: '',
      selectedYear: '',
      yearList: [],
      deptSearchText: '',
      evalSearchText: '',
      completedEvalList: '',
      bscList: [],
      selectedTableEval: ''
    }
  },
  methods: {
    handleSwitch (event, item) {
      var formSubmit = {}
      formSubmit.id = item.bmemd_id
      formSubmit.head_a_id = event === '1' ? 2 : 3
      this.$http
        .post('mpp-bsc-maintenance-head-approval', formSubmit)
        .then(res => {
          if (res.data.StatusCode) {
            this.$message({
              showClose: true,
              message: 'Form Submitted',
              type: 'success'
            })
            this.initGetBSCApproval()
          } else {
            this.$message({
              showClose: true,
              message: res.data.body,
              type: 'error'
            })
          }
        })
    },
    computeTotal (item) {
      var total = 0
      item.attributes.forEach(e => {
        total += parseFloat(e.weight)
      })
      if (isNaN(total)) {
        return ''
      }
      return total.toFixed(1) + '%'
    },
    viewBSC (item) {
      var formSubmit = {}
      this.selectedSection = item
      formSubmit.bd_id = item.bd_id
      formSubmit.bms_id = item.bms_id
      formSubmit.bj_id = item.bj_id
      formSubmit.bds_id = item.bds_id
      this.$http
        .post('mpp-view-bsc', formSubmit)
        .then(res => {
          console.log(res)
          if (res.data.StatusDescription === 'Sorry! Nothing was found.') {
            this.bscTableData = []
          } else {
            this.bscTableData = res.data.body
            this.viewSection = true
          }
        })
    },
    getSection (item) {
      this.selectedTableEval = item
      var formSubmit = {}
      formSubmit.bd_id = item.bd_id
      formSubmit.bms_id = item.bms_id
      formSubmit.evaluation_id = item.id
      formSubmit.hemed_id = item.hemed_id
      this.$http
        .post('mpp-section-list', formSubmit)
        .then(res => {
          console.log(res)
          if (res.data.StatusDescription === 'Sorry! Nothing was found.') {
            this.sectionTable = []
            this.selectedTableEval = ''
          } else {
            this.sectionTable = res.data.body
          }
        })
    },
    getBSCApproval () {
      this.sectionTable = []
      var formSubmit = {}
      formSubmit.year = this.selectedYear
      formSubmit.id = this.selectedEval === '' ? 0 : this.selectedEval
      this.$http
        .post('mpp-evaluation-approval-list', formSubmit)
        .then(res => {
          console.log(res)
          if (res.data.StatusDescription === 'Sorry! Nothing was found.') {
            this.approvalTable = []
          } else {
            this.approvalTable = res.data.body
          }
        })
    },
    initGetBSCApproval () {
      var formSubmit = {}
      formSubmit.year = null
      formSubmit.id = null
      this.$http
        .post('mpp-evaluation-approval-list', formSubmit)
        .then(res => {
          console.log(res)
          if (res.data.StatusDescription === 'Sorry! Nothing was found.') {
            this.approvalTable = []
          } else {
            this.approvalTable = res.data.body
          }
        })
    },
    onResize () {
      this.windowWidth = window.innerWidth
    },
    hello (hello) {
      console.log(hello)
    },
    getCompletedEval () {
      this.$http
        .get('mpp-completed-evaluation-list')
        .then(res => {
          this.completedEvalList = res.data.body
          var uniqueData = [...res.data.body.reduce((map, obj) => map.set(obj.year, obj), new Map()).values()]
          console.log(uniqueData)
          this.yearList = uniqueData
        })
    }
  }
}
</script>
